import { Controller } from "@hotwired/stimulus";
import Handsontable from "handsontable";
window.Handsontable = Handsontable; // TODO: windowオブジェクトにセットはやめる

export default class extends Controller {
  static values = {
    hotData: Array,
    currentWeek: String,
  };

  connect() {
    this._buildDataTable();
  }

  _buildDataTable() {
    const hot_container = document.getElementById("handsone-table-product-shippables");
    if (hot_container) {
      hot_container.innerHTML = "";

      window.hot = new Handsontable(hot_container, hotSettings());

      window.hot.addHook("afterChange", (change, source) => {
        let is_edit = false;
        if(change != null) {
          change.forEach(function([row, prop, oldValue, newValue]) {
            if((!!oldValue || !!newValue) && oldValue != newValue) {
              is_edit = true;
            }
          })
        }
        if(!is_edit) return;

        let updateParams = [];
        change.forEach(function (data) {
          const productId = window.hot.getCellMeta(data[0], 0).product_id;
          updateParams.push({
            column: data[1],
            value: data[3],
            product_id: productId,
          });
        });

        this.updateShippable(updateParams);
      });

      $.each(this.hotDataValue, function (k, v) {
        window.hot.setCellMeta(k, 0, "product_id", v.product_id);
      });
    }
  }

  updateShippable(params) {
    $.ajax({
      method: "POST",
      url: `/bulk_product_shippables`,
      data: {
        data: params,
      },
      dataType: "json",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      success: function (_) {
        $(".js-update-msg").text("変更を保存しました。");
        setTimeout( function() {$(".js-update-msg").text("　") }, 3000);
        $(".js-time-span").text("最終更新：1分以内");
      },
      error: function (response) {
        showAlert(`${response.responseJSON.error}`);
      },
    });
  }
}
