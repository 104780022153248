import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["notice", "alert"];
  
  connect() {
    $(".js-front-alert").delay(5000).slideUp(200, function () {
      $(this).css("display", "none");
    });
  
    $(".js-close-alert").on("click", function () {
      $(".js-front-alert").css("display", "none");
    });
  
    $(document).on('keyup', function (e) {
      if (e.key === "Escape") {
        $(".js-front-alert").css("display", "none");
      }
    });
  }
}
