import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    productId: Number,
  };

  connect() {
    $('input[name="product_shippable[quantity_type]"]').on(
      "change",
      function () {
        if ($(this).val() === "weekly") {
          $(".js-delivery-day").addClass("d-none");
          $(".js-delivery-week").removeClass("d-none");
        } else {
          $(".js-delivery-week").addClass("d-none");
          $(".js-delivery-day").removeClass("d-none");
        }
      }
    );
  }

  increaseValue(e) {
    const value = Number($(e.currentTarget.previousElementSibling).val());

    let nextValue = value + $(e.currentTarget).data("value");
    if (nextValue > 9999) { // ProductShippable::SHIPPABLE_QUANTITY_MAX
      nextValue = 9999;
    }
    $(e.currentTarget.previousElementSibling).val(nextValue);
  }

  decreaseValue(e) {
    const value = Number($(e.currentTarget.nextElementSibling).val());
    let nextValue = value - $(e.currentTarget).data("value");
    if (nextValue < 0) { // ProductShippable::SHIPPABLE_QUANTITY_MIN
      nextValue = 0;
    }
    $(e.currentTarget.nextElementSibling).val(nextValue);
  }

  copyAboveValue(e) {
    const previousValue = Number(
      $(e.currentTarget.closest(".js-parent").previousElementSibling).find("input.js-input-value").val()
    );
    $(e.currentTarget.closest(".js-parent")).find("input.js-input-value").val(previousValue);
  }

  copyFromPrevWeek(e) {
    e.preventDefault();
    $.ajax({
      method: "GET",
      url: `/product_shippables/${this.productIdValue}/copy_shippable?week=${$(
        e.currentTarget
      ).data("week")}`,
      dataType: "script",
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
    });
  }
}
