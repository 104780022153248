import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    maxFileSize: Number,
    maxFiles: Number,
  };

  connect() {
    this.inputTarget.addEventListener("change", this.handleFileChange.bind(this));
    $(".js-remove-image").on("click", this.removeFile.bind(this));
  }

  handleFileChange(event) {
    const files = Array.from(this.inputTarget.files);
    const totalFile = $("input.js-image-file-input").length;
    const fileSizeInvalid = files.some(file => file.size > this.maxFileSizeValue);

    if (totalFile + files.length > this.maxFilesValue || fileSizeInvalid) {
      alert(`最大${this.maxFileSizeValue / (1024 * 1024)}MB以下のファイルを${this.maxFilesValue}ファイルまでアップロードすることができます。`);
      this.inputTarget.value = null;
      return;
    }

    files.forEach(this._uploadFile.bind(this));
    this.inputTarget.value = null;
  }

  _uploadFile(file) {
    this._showSpinner();  // スピナーを表示

    const upload = new DirectUpload(
      file,
      this.inputTarget.getAttribute("data-direct-upload-url"),
      this.inputTarget.getAttribute("data-direct-upload-token"),
      this.inputTarget.getAttribute("data-direct-upload-attachment-name")
    );

    upload.create((error, blob) => {
      this._hideSpinner();  // スピナーを非表示

      if (error) {
        console.log("File upload error!");
        return;
      }

      const hiddenField = this._createHiddenField(blob);
      document.querySelector("form").appendChild(hiddenField);

      this._imagesPreview(blob);
    });
  }

  _createHiddenField(blob) {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.value = blob.signed_id;
    hiddenField.id = blob.signed_id;
    hiddenField.classList.add("js-image-file-input");
    hiddenField.name = this.inputTarget.name;
    return hiddenField;
  }

  _imagesPreview(blob) {
    if (!$(".js-image-added-wrapper").length) {
      $(".js-image-wrapper").append('<div class="images-review d-flex align-items-center js-image-added-wrapper"></div>');
    }

    const element = `
      <div class="image-container parent-image js-add-preview">
        <img src="${blob.preview_url}">
        <i class="icon-clear" data-action="click->direct-upload#removeFile" data-signed="${blob.signed_id}"></i>
      </div>`;
    $(".js-image-added-wrapper").append(element);
  }

  _showSpinner() {
    if (!$(".js-image-added-wrapper").length) {
      $(".js-image-wrapper").append('<div class="images-review d-flex align-items-center js-image-added-wrapper"></div>');
    }

    const spinnerElement = `
      <div class="spinner-border js-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>`;
    $(".js-image-added-wrapper").append(spinnerElement);
  }
  
  _hideSpinner() {
    $(".js-spinner").remove();
  }

  removeFile(e) {
    const id = $(e.currentTarget).data("signed");
    $(`#${id}`).remove();
    e.currentTarget.closest(".parent-image").remove();
  }
}
