showNotice = message => {
  $(".front-alert.alert-success").css("display", "block");
  $("#notice-message").text(message);
}
hideNotice = () => {
  $(".front-alert.alert-success").css("display", "none");
}

showAlert = (message) => {
  $(".front-alert.alert-danger").css("display", "block");
  $("#alert-message").text(message);
}
hideAlert = () => {
  $(".front-alert.alert-danger").css("display", "none");
}
