import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "broadCategoryText", "broadCategoryBadge", "broadCategoryList",
    "middleCategoryText", "middleCategoryBadge", "middleCategoryList",
    "smallCategoryText", "smallCategoryBadge", "smallCategoryList",
    "productItemText", "productItemBadge", "productItemList",
    "productItemSearchForm", "productItemSearchBox",
  ];
  static values = {
    broadCategory: String,
    middleCategory: String,
    smallCategory: String,
    item: String,
    productItemSearchText: String,
    categoryMapping: Array,
  };

  connect() {
  }

  // call backs
  broadCategoryValueChanged() {
    if (this.broadCategoryValue) {
      // badgeの表示
      this.broadCategoryBadgeTarget.style.display = "inline-block";
      this.broadCategoryTextTarget.textContent = this.broadCategoryValue;
      // liにselectedクラスをadd
      this.broadCategoryListTargets.forEach((element) => { element.classList.remove("selected"); });
      let selectedElement = this.broadCategoryListTargets.find(element => element.dataset.broadCategory === this.broadCategoryValue);
      if (selectedElement) { selectedElement.classList.add("selected"); }
    } else {
      // badgeの非表示
      this.broadCategoryBadgeTarget.style.display = "none";
      this.broadCategoryTextTarget.textContent = null;
      // liのselectedクラスをremove
      this.broadCategoryListTargets.forEach((element) => { element.classList.remove("selected"); });
    }
    // listの検索
    this._searchList();
  }

  middleCategoryValueChanged() {
    if (this.middleCategoryValue) {
      // badgeの表示
      this.middleCategoryBadgeTarget.style.display = "inline-block";
      this.middleCategoryTextTarget.textContent = this.middleCategoryValue;
      // liにselectedクラスをadd
      this.middleCategoryListTargets.forEach((element) => { element.classList.remove("selected"); });
      let selectedElement = this.middleCategoryListTargets.find(element => element.dataset.middleCategory === this.middleCategoryValue);
      if (selectedElement) { selectedElement.classList.add("selected"); }
    } else {
      // badgeの非表示
      this.middleCategoryBadgeTarget.style.display = "none";
      this.middleCategoryTextTarget.textContent = null;
      // liのselectedクラスをremove
      this.middleCategoryListTargets.forEach((element) => { element.classList.remove("selected"); });
    }
    // listの検索
    this._searchList();
  }

  smallCategoryValueChanged() {
    if (this.smallCategoryValue) {
      // badgeの表示
      this.smallCategoryBadgeTarget.style.display = "inline-block";
      this.smallCategoryTextTarget.textContent = this.smallCategoryValue;
      // liにselectedクラスをadd
      this.smallCategoryListTargets.forEach((element) => { element.classList.remove("selected"); });
      let selectedElement = this.smallCategoryListTargets.find(element => element.dataset.smallCategory === this.smallCategoryValue);
      if (selectedElement) { selectedElement.classList.add("selected"); }
    } else {
      // badgeの非表示
      this.smallCategoryBadgeTarget.style.display = "none";
      this.smallCategoryTextTarget.textContent = null;
      // liのselectedクラスをremove
      this.smallCategoryListTargets.forEach((element) => { element.classList.remove("selected"); });
    }
    // listの検索
    this._searchList();
  }

  itemValueChanged() {
    if (this.itemValue) {
      // badgeの表示
      this.productItemBadgeTarget.style.display = "inline-block";
      this.productItemTextTarget.textContent = this.itemValue;
      // liにselectedクラスをadd
      this.productItemListTargets.forEach((element) => { element.classList.remove("selected"); });
      let selectedElement = this.productItemListTargets.find(element => element.dataset.productItem === this.itemValue);
      if (selectedElement) { selectedElement.classList.add("selected"); }
      // product-item-search-boxの非表示
      this.productItemSearchBoxTarget.style.display = "none";
    } else {
      // badgeの非表示
      this.productItemBadgeTarget.style.display = "none";
      this.productItemTextTarget.textContent = null;
      // liのselectedクラスをremove
      this.productItemListTargets.forEach((element) => { element.classList.remove("selected"); });
      // product-item-search-boxの表示
      this.productItemSearchBoxTarget.style.display = "block";
    }
    // listの検索
    this._searchList();
  }

  productItemSearchTextValueChanged() {
    if (this.productItemSearchTextValue) {
    } else {
      this.productItemSearchFormTarget.value = null;
    }
    // listの検索
    this._searchProductItemList();
  }

  // 検索リストの表示・非表示
  _searchList() {
    this._searchMiddleCategoryList();
    this._searchSmallCategoryList();
    this._searchProductItemList();
  }

  _searchMiddleCategoryList() {
    // 全表示
    this.middleCategoryListTargets.forEach((element) => { element.style.display = 'list-item'; });
    // 検索
    this.middleCategoryListTargets.forEach((element) => {
      if (!!this.broadCategoryValue && element.dataset.broadCategory !== this.broadCategoryValue) {
        element.style.display = 'none';
      }
    });
  }

  _searchSmallCategoryList() {
    // 全表示
    this.smallCategoryListTargets.forEach((element) => { element.style.display = 'list-item'; });
    // 検索
    this.smallCategoryListTargets.forEach((element) => {
      if (!!this.middleCategoryValue && element.dataset.middleCategory !== this.middleCategoryValue ||
          !!this.broadCategoryValue && element.dataset.broadCategory !== this.broadCategoryValue) {
        element.style.display = 'none';
      }
    });
  }

  _searchProductItemList() {
    // 全表示
    this.productItemListTargets.forEach((element) => { element.style.display = 'list-item'; });
    // 検索
    this.productItemListTargets.forEach((element) => {
      if (!!this.smallCategoryValue && element.dataset.smallCategory !== this.smallCategoryValue ||
          !!this.middleCategoryValue && element.dataset.middleCategory !== this.middleCategoryValue ||
          !!this.broadCategoryValue && element.dataset.broadCategory !== this.broadCategoryValue ||
          !!this.productItemSearchTextValue && !element.dataset.productItem.includes(this.productItemSearchTextValue)) {
        element.style.display = 'none';
      }
    });
  }

  // カテゴリクリック
  clickBroadCategory(event) {
    this.selectBroadCategory(event.target.dataset.broadCategory)
  }
  clickMiddleCategory(event) {
    this.selectMiddleCategory(event.target.dataset.middleCategory)
  }
  clickSmallCategory(event) {
    this.selectSmallCategory(event.target.dataset.smallCategory)
  }
  clickProductItem(event) {
    this.selectProductItem(event.target.dataset.productItem)
  }

  // 品目検索
  searchProductItem(event) {
    this.productItemSearchTextValue = event.target.value;
  }

  // select
  async selectBroadCategory(broadCategory) {
    this.broadCategoryValue = broadCategory;
    this.middleCategoryValue = "";
    this.smallCategoryValue = "";
    this.itemValue = "";
    this.productItemSearchTextValue = "";
    await this.updateIframe();
  }

  async selectMiddleCategory(middleCategory) {
    this.broadCategoryValue = this.searchMappingMiddleCategory(middleCategory).broad_category;
    this.middleCategoryValue = middleCategory;
    this.smallCategoryValue = "";
    this.itemValue = "";
    this.productItemSearchTextValue = "";
    await this.updateIframe();
  }

  async selectSmallCategory(smallCategory) {
    this.broadCategoryValue = this.searchMappingSmallCategory(smallCategory).broad_category;
    this.middleCategoryValue = this.searchMappingSmallCategory(smallCategory).middle_category;
    this.smallCategoryValue = smallCategory;
    this.itemValue = "";
    this.productItemSearchTextValue = "";
    await this.updateIframe();
  }

  async selectProductItem(productItem) {
    this.broadCategoryValue = this.searchMappingProductItem(productItem).broad_category;
    this.middleCategoryValue = this.searchMappingProductItem(productItem).middle_category;
    this.smallCategoryValue = this.searchMappingProductItem(productItem).small_category;
    this.itemValue = productItem;
    this.productItemSearchTextValue = "";
    await this.updateIframe();
  }

  // clear
  async clearBroadCategory() {
    this.broadCategoryValue = "";
    this.middleCategoryValue = "";
    this.smallCategoryValue = "";
    this.itemValue = "";
    this.productItemSearchTextValue = "";
    await this.updateIframe();
  }

  async clearMiddleCategory() {
    this.middleCategoryValue = "";
    this.smallCategoryValue = "";
    this.itemValue = "";
    this.productItemSearchTextValue = "";
    await this.updateIframe();
  }

  async clearSmallCategory() {
    this.smallCategoryValue = "";
    this.itemValue = "";
    this.productItemSearchTextValue = "";
    await this.updateIframe();
  }

  async clearProductItem() {
    this.itemValue = "";
    this.productItemSearchTextValue = "";
    await this.updateIframe();
  }

  // search mapping
  searchMappingMiddleCategory(MiddleCategory) {
    return this.categoryMappingValue.find(obj => obj.middle_category === MiddleCategory)
  }
  searchMappingSmallCategory(SmallCategory) {
    return this.categoryMappingValue.find(obj => obj.small_category === SmallCategory)
  }
  searchMappingProductItem(ProductItem) {
    return this.categoryMappingValue.find(obj => obj.item === ProductItem)
  }

  // iframe
  async updateIframe() {
    const url = this._iframeURL();
    this._replaceStateHistory(url);
    const turboFrameId = 'iframe';
    await this._turboFrame(url, turboFrameId);
  }
  _iframeURL() {
    const url = new URL('/supply_dashboard', window.location.origin);
    url.searchParams.append('broad_category', this.broadCategoryValue);
    url.searchParams.append('middle_category', this.middleCategoryValue);
    url.searchParams.append('small_category', this.smallCategoryValue);
    url.searchParams.append('item', this.itemValue);
    return url;
  }
  _replaceStateHistory(url) {
    history.replaceState('permit', null, url.pathname + url.search);
  }
  async _turboFrame(url, id) {
    const response = await fetch(url, {
      headers: { 'Turbo-Frame': id },
    });
    if (!response.ok) {
      showAlert('検索時にエラーが起きました。もう一度お試しください。');
    }

    const responseText = await response.text();
    const domParser = new DOMParser();
    const htmlDocument = domParser.parseFromString(responseText, "text/html");
    const newFrame = htmlDocument.getElementById(id);
  
    const frame = document.getElementById(id);
    frame.innerHTML = newFrame.innerHTML;
  }

  // collapse toggle
  toggleIcon(event) {
    const showIcon = event.currentTarget.querySelector('.js-show-icon');
    const hideIcon = event.currentTarget.querySelector('.js-hide-icon');

    showIcon.style.display = showIcon.style.display === 'none' ? 'inline' : 'none';
    hideIcon.style.display = hideIcon.style.display === 'none' ? 'inline' : 'none';
  }
}
