import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = process.env.NODE_ENV === 'development'
window.Stimulus   = application

export { application }

// stimlusデバッグ用
if (process.env.NODE_ENV === 'development') {
  window.stimlusInstance = (controllerName) => {
    return Stimulus.getControllerForElementAndIdentifier(
      document.querySelector(`[data-controller="${controllerName}"]`),
      controllerName
    )
  }
}
