import { Controller } from "@hotwired/stimulus";
import Handsontable from "handsontable";
import { isNullOrEmpty, isChanged, createIndexMap, monthDay } from "../front/handson_table_helper";

export default class extends Controller {
  static values = {
    plantingPeriod: String,
    weeks: Array,
    currentWeek: String,
    hotDatas: Array
  }
  static targets = ["updateMessage"]

  hot = null // Handsontableのインスタンス
  hotDatas = this.hotDatasValue // Handsontableのデータ

  connect() {
    this.initializeHot()
  }

  initializeHot() {
    const hotElement = document.querySelector('#handsone-table')
    const hotSettings = this.hotSettings()
    this.hot = new Handsontable(hotElement, hotSettings)
    this.loadHotData()
  }

  // Handsontableのデータを読み込む
  loadHotData() {
    this.hot.loadData(this.hotDatas)
  }

  // 「新品目の追加」処理
  addRow() {
    fetch('/bulk_ship_schedules/add', { // add_bulk_ship_schedules_path
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        planting_period: this.plantingPeriodValue,
      }),
    })
    .then(response => {
      if (!response.ok) { throw new Error('response was not ok') }
      return response.json()
    })
    .then(json => {
      const id = json.data.created_id
      this.hotDatas.push({id: id, display_product_name: "", ship_schedule_quantities: {}, is_manual_regist: true})
      this.loadHotData()
      this.hot.selectCell(this.hotDatas.length - 1, 0);
    })
    .catch(error => {
      console.error(error)
      showAlert('新品目の追加に失敗しました。')
    })
  }

  // 「削除」処理
  deleteRow(event) {
    if (!confirm('削除しますか？')) return
    const row = parseInt(event.currentTarget.dataset.row)
    const id = this.hotDatas[row].id
    fetch(`/bulk_ship_schedules/${id}`, { // bulk_ship_schedule_path
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    })
    .then(response => {
      if (!response.ok) { throw new Error('response was not ok') }
      this.hotDatas.splice(row, 1)
      this.loadHotData()
    })
    .catch(error => {
      console.error(error)
      showAlert('削除に失敗しました。')
    })
  }

  // 「前年分を反映」処理
  refrectPreviousShippableQuantity(event) {
    if (!confirm('前年分の出荷可能の実績を反映させますか？')) return
    const row = parseInt(event.currentTarget.dataset.row)
    let dataArray = this._refrectPreviousShippableQuantityData(row)
    this.hot.setDataAtCell(dataArray)
  };
  _refrectPreviousShippableQuantityData(row) {
    let indexMap = createIndexMap(this.hotColumns())

    let result = Object.entries(this.hotDatas[row].previous_shippable_quantities) // [["2021-01-01", 10], ["2021-01-02", 20]]
      .filter(([dateString, _]) => isNullOrEmpty(this.hotDatas[row].ship_schedule_quantities[dateString]))
      .map(([dateString, quantity]) => {
        let col = indexMap[`ship_schedule_quantities.${dateString}`]
        if (col === undefined) return null
        return [
          row,
          col,
          quantity
        ]
      })
      .filter(item => item !== null)

    return result
  }

  // 「更新」処理
  updateDatas(params) {
    if (Object.keys(params).length === 0) return
    fetch('/bulk_ship_schedules', { // bulk_ship_schedules_path
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        data: params
      }),
    })
    .then(response => {
      if (!response.ok) { throw new Error('response was not ok') }
      return response.json()
    })
    .then(json => {
      if (json.status !== 'success') { throw new Error('response status was not success') }
      this.updateMessageTarget.textContent = "変更を保存しました。"
      setTimeout(() => this.updateMessageTarget.textContent = "　", 3000)
    })
    .catch(error => {
      console.error(error)
      showAlert('保存に失敗しました。')
    })
  }
  _updateParams(changes) {
    if (changes === null) return
    let params = {}
    changes.forEach(change => {
      if (!isChanged(change[2], change[3])) return // 変更がない場合はスキップ
      const row = this.hot.toPhysicalRow(change[0])
      const hotData = this.hotDatas[row]
      params[hotData.id] = {
        ...params[hotData.id],
        [change[1]]: change[3]
      }
    })
    return params
  }

  hotSettings() {
    return {
      columns: this.hotColumns(),
      data: [],
      columnSorting: true,
      height: "calc(100vh - 178px)",
      fixedColumnsLeft: 2,
      viewportColumnRenderingOffset: 60,
      currentRowClassName: 'currentRow',
      currentColClassName: 'currentCol',
      afterChange: this.hotAfterChange.bind(this),
      licenseKey: "non-commercial-and-evaluation",
    }
  }

  hotColumns() {
    const hotDisplayProductName = {
      title: '商品',
      data: 'display_product_name',
      className: 'bold text-left display-product-name',
      width: 280,
      readOnly: true,
      renderer: this.displayProductNameRender.bind(this),
    }

    const functionBtn = {
      title: '機能',
      data: '',
      className: 'text-center function-btn',
      width: 90,
      readOnly: true,
      renderer: this.functionBtnRender.bind(this),
    }

    const shipScheduleQuantityColumns = this.weeksValue.map((dateString) => {
      return this._shipScheduleQuantityColumn(dateString)
    })

    return [
      hotDisplayProductName,
      functionBtn,
      shipScheduleQuantityColumns,
    ].flat()
  }

  _shipScheduleQuantityColumn(dateString) {
    const currentWeek = new Date(this.currentWeekValue)
    const targetWeek = new Date(dateString)
    const comfirmWeek = new Date(currentWeek.getFullYear(), currentWeek.getMonth() + 3, 1) // 2カ月前までは確認対象
    let headerLabel = ''
    if (dateString == this.currentWeekValue) {
      headerLabel = `<span class="header-label current-week">今週</span>`
    }
    else if (currentWeek < targetWeek && targetWeek < comfirmWeek) {
      headerLabel = `<span class="header-label comfirm-week">要確認</span>`
    }

    return {
      title: `${headerLabel}<br>${monthDay(dateString)}`,
      data: `ship_schedule_quantities.${dateString}`,
      className: 'editable text-center',
      type: 'numeric',
      width: 46,
      renderer: this.shipScheduleQuantityRender.bind(this),
      validator: this.shipScheduleQuantityValidator.bind(this),
    }
  }

  // ----- HandsontableのカスタムRenderers -----
  displayProductNameRender(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);

    let innerHtmlText = value
    if (innerHtmlText === null ) { innerHtmlText = '' }

    // placeholder
    if (this.hotDatas[cellProperties.row].is_manual_regist && isNullOrEmpty(value)) {
      innerHtmlText = `<p class="product-placeholder"><span class="instruction">商品名を入力してください</span><br><span class="example">（例）ミズナ 150g [袋]</span></p>`
    } else {
      innerHtmlText = `<p>${innerHtmlText}</p>`
    }

    // 編集可・不可
    if (this.hotDatas[cellProperties.row].is_manual_regist) {
      td.className += ' editable'
      cellProperties.readOnly = false
    }

    // 市況ボタン
    if (!this.hotDatas[cellProperties.row].is_manual_regist) {
      const params = {
        item: this.hotDatas[cellProperties.row].item_name,
      }
      const url = `/supply_dashboard?${$.param(params)}` // supply_dashboard_path
      innerHtmlText += `<a href="${url}" target="_blank" class="btn btn-supply-dashboard"><i class="icon-supply-dashboard-small"></i><span>市況</span></a>`
    }

    td.innerHTML = innerHtmlText
  }

  functionBtnRender(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);

    let innerHtmlText = value

    // 前年分を反映ボタン
    if (!this.hotDatas[cellProperties.row].is_manual_regist) {
      innerHtmlText = `<button class="btn btn-copy-previous-year" data-action="click->bulk-ship-schedule#refrectPreviousShippableQuantity" data-row="${cellProperties.row}"><i class="icon-copy-small"></i><span>前年分<br>を反映</span></button>`
    }

    // 削除ボタン
    if (this.hotDatas[cellProperties.row].is_manual_regist) {
      innerHtmlText = `<button class="btn btn-delete-row" data-action="click->bulk-ship-schedule#deleteRow" data-row="${cellProperties.row}"><i class="icon-trash-small"></i><span>削除</span></button>`
    }

    td.innerHTML = innerHtmlText
  }

  shipScheduleQuantityRender(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);

    let innerHtmlText = value
    if (innerHtmlText === null) { innerHtmlText = '' }

    const date_str = prop.slice(-10)
    const ship_schedule_quantity = value
    const agreement_quantity = this.hotDatas[cellProperties.row].agreement_quantities?.[date_str]
    const previous_shippable_quantity = this.hotDatas[cellProperties.row].previous_shippable_quantities?.[date_str]

    // 約束数と前年出荷可能数の表示
    if (!!agreement_quantity) {
      innerHtmlText += `<p class="placeholder-quantity"><span class="agreement-quantity">${agreement_quantity}</span></p>`
    }
    if (!!previous_shippable_quantity) {
      innerHtmlText += `<p class="placeholder-quantity"><span class="previous-shippable-quantity">(${previous_shippable_quantity})</span></p>`
    }

    // 背景色
    if (new Date(date_str) < new Date(this.currentWeekValue)) {
      td.className += ' grayout'
    }
    else if(BigNumber(ship_schedule_quantity).lt(BigNumber(agreement_quantity)) || (!ship_schedule_quantity && !!agreement_quantity && agreement_quantity != 0)) {
      td.className += ' warning'
    }

    td.innerHTML = innerHtmlText
  }

  // ----- HandsontableのカスタムValidators -----
  shipScheduleQuantityValidator(value, callback) {
    if (Number(value) >= 0 && Number(value) <= 99999) { // ShipSchedule::SHIP_SCHEDULE_QUANTITY_MAX
      callback(true);
    } else {
      callback(false);
    }
  };

  // ----- Handsontable イベントハンドラ -----
  hotAfterChange(changes) {
    if (changes === null) return
    this.updateDatas(this._updateParams(changes))
  }
}
