import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(document).on('keyup', function (e) {
      if (e.key === "Escape") {
        $(".js-notification-modal").addClass("d-none");
      }
    });
  }

  showNotification(e) {
    const id = $(e.currentTarget).data("id");
    $.ajax({
      method: "GET",
      url: `/notifications/${id}`, // notification_path
      dataType: "script",
    });
  }

  closeModal(_e) {
    $(".js-notification-modal").addClass("d-none");
  }
}
