export function isNullOrEmpty(str) {
  return str === null || str === undefined || str === ''
}

export function isChanged(before, after) {
  if (isNullOrEmpty(before)) before = ''
  if (isNullOrEmpty(after)) after = ''
  return before !== after
}

/**
 * hotColumnsを引数に、{ id: 0, name: 1, ... }のようなオブジェクトを返す。
 */
export function createIndexMap(hotColumns) {
  return hotColumns.reduce((acc, cur, idx) => {
    acc[cur.data] = idx;
    return acc;
  }, {})
}

/**
 * '2023-12-08'の文字列に対して、'12/8'の文字列を返す。
 * @param {string} dateString
 * @return {string}
 */
export function monthDay(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}$/
  if (!regex.test(dateString)) { return null }

  const splitedDateString = dateString.split("-")
  const year = splitedDateString[0]
  const month = parseInt(splitedDateString[1])
  const day = parseInt(splitedDateString[2])
  return `${month}/${day}`
}
